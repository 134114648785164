$default-family: sans-serif;
$family-regular: 'Brandon Grotesque', $default-family;
$family-bold: 'Brandon Grotesque', $default-family;
$font-body: 14px;
$font-base: 1rem;
$font-sm: 0.75rem;
$font-lg: 1.15rem;
$font-xl: 1.5rem;
$cta-color: #d4612b;
$cta-light: #f6c192;
$cta-very-light: #f8dfc6;
$color-white: #ffffff;
$primary-color: #464646;
$secondary-color: #7e808c;
$font-mobile-base: 13px;
$font-mobile-sm: 12px;

body {
  overflow-y: scroll !important;
  font-family: 'Brandon Grotesque';
}
th,td,.default-font{
  font-family: 'Brandon Grotesque' !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Brandon Grotesque';
  font-weight: 500;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: calc(1.325rem + 1.5vw);
  }
  .h2,
  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
  .h3,
  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  .h4,
  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
}

%primary-button-light {
  border: 2px solid $cta-color;
  background-color: white;
  color: $cta-color;
}

%primary-button-light-hover {
  @extend %primary-button-light;
  background-color: $cta-light;
}

.btn-primary {
  background-color: #d4612b !important;
}
.btn-outline-primary {
  color: #d4612b !important;
  background-color: #ffffff !important;
  border-color: #d4612b !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #d4612b !important;
  border-color: #d4612b !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #d4612b;
}
.tw-text-cta {
  color: #d4612b;
  cursor: pointer;
}
.hover\:tw-bg-cta:hover{
  background-color: #d4612b !important;
  background-color: rgba(212, 97, 43, 1) !important;
}
%boq-info-ul {
  ul {
    margin-bottom: 0;
  }
  ul li {
    cursor: default;
  }
  ul li:not(:first-child):before {
    content: '\2022';
    padding-right: 0.5rem;
    color: $secondary-color;
  }
}

%responsive-table {
  thead {
    display: flex;
    width: 100%;
    text-transform: capitalize;
    background-color: #f2f2f2 !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: thin solid #dee2e6;
    border-bottom: 1px solid transparent;
    tr {
      display: flex;
      width: 100%;

      th {
        text-transform: capitalize;
        font-weight: 700;
        color: #000;
        width: 100%;
        span {
          font-weight: 700;
        }
      }
      th:first-child {
        // width: 30%;
      }
      th:not(:first-child) {
        // width: 20%;
        text-align: center;
        span {
          justify-content: center;
        }
      }
      th:last-child {
        // width: 20%;
        text-align: right;
        span {
          justify-content: flex-end;
        }
      }
    }
  }
  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    tr {
      display: flex;
      align-items: center;
      //justify-content: center;
      width: 100%;
      td {
        width: 100%;
        min-height: 60px;
        display: flex;
        align-items: center;
        max-height: 60px;
      }
      td:first-child {
        // width: 30%;
      }
      td:not(:first-child) {
        // width: 20%;
        text-align: center;
        justify-content: center;
      }
      td:last-child {
        // width: 20%;
        text-align: right;
        justify-content: flex-end;
      }
    }
  }
}

%edit-rate-btn {
  button.ma-rate-update-btn,
  button.la-rate-update-btn {
    display: inline-flex;
    padding: 0.05rem 0.2rem;
    border: 1px solid $cta-color;
    background-color: $cta-very-light;
    border-radius: 999999px !important;
    margin-left: 0.3rem;
    border: thin solid orange;
    border-radius: 40px !important;
    width: 26px;
  }
}

%display-rate-edit-button {
  tr:hover {
    td {
      @extend %edit-rate-btn;
    }
  }
}

table.responsive-table {
  @extend %responsive-table;
  @extend %display-rate-edit-button;
}

.img-thumbnail{
  cursor: pointer;
}