nav {
  background-color: #ffffff;
}

.hp-logo {
  width: 50px;
  height: 27px;
}

.active-menu {
  border-bottom-color: #d4612b;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  color: #24292e;
  font-weight: 600;
}
.menu-item {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 0rem;
  flex:none;
}
div.select-wrapper.tw-relative{
  flex: none;
}

// React's Link messes with bootstrap,
// so lets override them
li > a {
  text-decoration: none !important;
  color: #666666;
}

.navbar-brand > a {
  color: #d4612b !important;
  text-decoration: none !important;
  text-transform: uppercase;
  font-family: 'CircularStd Medium', sans-serif;
  letter-spacing: 2px;
}

.profile-image {
  border-radius: 50%;
  width: 25px;
}

.bg-light {
  background-color: #ffffff !important;
}

.logout-dropdown {
  cursor: pointer;
}
.navbar .navbar-collapse a.btn.btn-outline-secondary {
  color: #fff !important;
  background-color: #d4612b;
  border-color: #d4612b;
  border-radius: 0;
  padding: 5px 35px;
}

.sticky-top-bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

// @FE developer: Have a look at the below class
.book-consultation {
  border: thin solid;
}
span.book-consultation:hover {
  background-color: #fff0e2;
}

button.book-consultation:hover {
  background-color: #fff0e2;
}

.dropdown-item {
  font-size: 0.875rem; // base font is 16. This is 14
}
li.dropdown.nav-item{
  float: left;
  width: 100%;
}
.dropdown-item:hover {
  background-color: #fff0e2 !important;
  color: #111111 !important;
}
.nav-link {
  .profile-name {
    font-family: 'Muli Bold', sans-serif;
    background-color: #d4612b;
    color: #fff0e2;
  }
}

@media (min-width: 768px) {
  nav .common-menu-div ul li {
    margin: 0.1rem 10px 0.1rem 0;
  }
}

@media (max-width: 768px) {
  .menu-item {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    text-align: left;
    display: block;
    width: 100%;
    border-bottom: thin solid #ddd;
    padding-bottom: 5px;
    //margin-top: 1rem;
  }
  .navbar-nav{
    padding-top: 1.5rem;
  }
  #navbarTogglerDemo02 ul.navbar-nav li {
    text-align: center;
    margin:0px;
 }
 #navbarTogglerDemo02 ul.navbar-nav .select-wrapper{
  width: 100%;
 }
}