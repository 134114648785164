@use '../../../styles/base';

.h-50vh {
  height: 50vh;
}
.h-min-50vh {
  min-height: 50vh;
}
.h-max-50vh {
  min-height: 50vh;
}

.profile-setup {
  background-color: white;
  .choose-profile-div {
    display: flex;
    align-items: center;
    //margin-top: 5rem;
    justify-content: center;
    align-items: center;
  }
  & > :first-child {
    h4 {
      font-size: 2rem;
    }
  }
  .selected {
    border-color: base.$cta-color;
  }
  .user-type-selection {
    height: 100%;
    button {
      border-radius: 0.25em !important;
    }
  }
  .primary-button {
    border-radius: 0.25em !important;
  }
  .left-section {
    background-color: #faece5;
    background-repeat: repeat-x;
    background-position: top right;
  }
  .form-div {
    margin: 1rem;
    button {
      font-weight: bold;
    }
  }
}
