@use './base';
@import './atomic.scss';

html {
  body {
    font-size: 14px;
    background: #f5f5f5 !important; //#fbf7f4 for reference
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
  }
}
ol,
ul {
  padding-left: 0px !important;
}
//always take full height of the browser

.full-height {
  height: 100%;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

//end

.white-background-color {
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
}

// .container {
//   margin-top: 5%;
//   margin-bottom: 5%;
// }

.navbar-brand {
  color: #ff8123 !important;
}

.App-header {
  background-color: #f8fafc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.occupy-full-screen {
  margin-right: -15px;
  margin-left: -15px;
}

.tabs-container {
  background-color: #ffffff;
  padding: 20px 10px 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
}

// tailwind messes with <img/>
// sets display as block
img {
  display: inline !important;
}

// li {
//   cursor: pointer;
// }

input:focus {
  outline-color: #ff8123;
}

.infoContainer {
  background: #ffffff;
  border-radius: 5px;
}

.secondOrder {
  margin-top: 20px;
}

.material-div {
  &:hover {
    @extend %edit-rate-btn;
  }
}

.h-fit-content {
  height: fit-content;
}

.uppy-Root {
  z-index: 9999;
}

.text-grey {
  color: grey;
}

.v2-form {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
}

.grey-border {
  border: 2px solid #e7e7e7;
}

.grey-border-img {
  border: 2px inset #e7e7e7;
}

.v2-form label {
  font-weight: 600;
}

.center-content {
  display: flex;
  align-content: center;
  justify-content: center;
}

.center-align {
  text-align: center;
}

.round-border {
  border-radius: 5px !important;
}

.error-message {
  font-weight: 600;
  color: red;
}

.line {
  width: 80px;
  height: 2px;
  background: #ccc;
}

.steps {
  display: flex;
  color: #666;
  font-size: 14px;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-direction: column;
  font-weight: bold;
  border: thin solid #ccc;
}
.steps.active {
  background-color: #ccc;
  color: #fff;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.pointer {
  cursor: pointer;
}
