.vendors {
  .categories {
    span {
      position: relative;
      z-index: 1;
    }
    a {
      position: relative;
    }

    a:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }

    a:last-child:before {
      display: none;
    }
  }
}

.filter {
  .info {
    background-color: #fafafa;
  }
}

.card-info {
  background-color: #fafafa;
}

:root {
  --animate-delay: 0.5s;
}

.accordion {
  border: 1px solid #edf2f7 !important;
}

.accordion__item + .accordion__item {
  border-color: transparent !important;
}

.accordion__heading {
  position: relative;
}

.accordion__button {
  padding: 1em !important;
  background-color: #ffffff !important;
}

.accordion__button:before {
  transform: rotate(45deg) !important;
  top: 22px;
  position: absolute;
  right: 4px;
  transition: 0.5s transform ease;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(-135deg) !important;
}

.accordion__panel {
  background: #ffffff !important;
}

.accordion__heading .accordion__button {
  color: #718096;
  font-weight: 600;
  font-size: 1.1em;
}

.accordion__panel label {
  text-transform: capitalize;
  cursor: pointer;
}

.accordion__panel input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 4px solid #ffa500;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin: 8px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #f2f2f2;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #fc8118;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
