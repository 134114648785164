.primary-button {
  font-size: 1rem;
  padding: 1rem;
  background-color: #d4612b;
  border: #d4612b;
  &:hover {
    background-color: #d4612b;
    border: #d4612b;
  }
}
