.loading-container {
  background: #f2f2f2;
  position: relative;
  overflow: hidden;
  background-image: none;
}

.loading-container.logo {
  background-image: url('../../../assets/logo.png');
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-color: #f2f2f2;
  background-size: 150px;
  filter: grayscale(100%);
}

.loading-container:after {
  content: '';
  background-color: #333;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-name: loader-animate;
  animation-timing-function: linear;
  background: -webkit-linear-gradient(
    to right,
    rgba(62, 62, 62, 0) 0%,
    rgba(171, 171, 171, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: -o-linear-gradient(
    to right,
    rgba(62, 62, 62, 0) 0%,
    rgba(171, 171, 171, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: linear-gradient(
    to right,
    rgba(62, 62, 62, 0) 0%,
    rgba(218, 218, 218, 0.6) 60%,
    rgba(255, 255, 255, 0) 100%
  );
}

@keyframes loader-animate {
  0% {
    left: -40%;
  }
  100% {
    left: 100%;
  }
}
