.simple-modal {
  .MuiDialogTitle-root {
    padding: 0 0 0.3rem 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .project-timeline-modal{
    padding-top: 35px;
    .close-btn{
      margin-top: 55px;
      z-index: 10000;
    }
  }
}