.faq-page h3 {
  margin: 1rem 0 2rem;
  font-size: 1.75rem;
}

.faq-page .card-header {
  background: #fff;
  font-size: 1.2em;
  border-bottom: none;
  padding: 1em 0;
  font-weight: bold;
  color: #7e808c;
}

.card-body p {
  color: #7e808c;
}

.faq-question {
  margin-bottom: 0;
  margin-right: 0.5em;
}

.faq-page .accordion {
  padding: 0em;
  background: #fff;
}

.accordion .card {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0;
}

.accordion .card:last-child {
  border: none !important;
}

.accordion .card-body ul li {
  font-size: 1.1em;
  list-style: disc;
  margin-bottom: 1em;
}

.accordion .card-body p {
  font-size: 1.1em;
}

.accordion .card-body ul {
  list-style: disc;
  padding-left: 1em;
}

.faq-page .card-header:after {
  content: '\f078';

  white-space: pre;
  position: absolute;
  top: 10px;
  margin-left: 0.5rem;
  padding-left: 1em;
  right: 0.2em;
  font-size: 2.2em;
  font-family: FontAwesome;
  font-size: 18px;
}

.faq-page .card-header.active:after {
  content: '\f077';
}

.faq-page .card-header {
  position: relative;
}

.faq-page .card-header {
  padding: 3em;
}

.faq-page .card-header > p.faq-question {
  cursor: pointer;
}

.faq-display-more {
  animation: display-more linear 2s;
}

@keyframes display-more {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
