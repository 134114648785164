.forgot-password-container {
  padding: 2.5rem 1.8rem;
  background: #ffff;
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.forgot-password-container a {
  color: #ff8123;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password-container h1 {
  font-size: 1.5rem;
}

.forgot-password-container form {
  margin: 1rem 0;
}

.forgot-password-container label {
  font-size: 0.93rem;
}
