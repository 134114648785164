.hc-placeholder {
  width: 100%;
  height: 240px;
  background-image: url('../../../assets/logo.png');
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-color: #f2f2f2;
  background-size: 150px;
  filter: grayscale(100%);
}

.hc-logo-placeholder {
  width: 90px;
  background-image: url('../../../assets/logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f2f2f2;
  background-size: 30px;
  filter: grayscale(100%);
}
