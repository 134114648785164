.btn-primary {
  background-color: #ff8123 !important;
  border-color: #ff8123 !important;
}

.upload-container {
  padding: 10px;
  border-radius: 6px;
  margin-top: 20%;
  background: #f6f8fa;
  color: #717171;
}

.form-container {
  text-align: center;
}

.home-container {
  margin: 0 auto;
  text-align: center;
}

//li {
//  list-style: none;
//}

.suggestion-container {
  text-align: left;
}

.button-container {
  text-align: center;
}
