.equals {
  box-sizing: border-box;
  height: 8px;
  width: 1px;
  border: 1px solid #979797;
  transform: rotate(90deg);
}

.oval {
  color: #979797;
}
