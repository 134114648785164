.regular {
  font-family: 'CircularStd Book', sans-serif;
}

.semi-bold {
  font-family: 'CircularStd Medium', sans-serif;
}

.bold {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.black {
  font-family: 'CircularStd Black', sans-serif;
}

// .font-extra-tiny {
//     font-size: $font-size-base * 0.625; // 10px
// }

// .font-tiny {
//     font-size: $font-size-base * 0.75; // 12px
// }

// .font-small {
//     font-size: $font-size-base * 0.875; // 14px
// }

// .font-regular {
//     font-size: $font-size-base; // 16px
// }

// .font-medium {
//     font-size: $font-size-base * 1.125; // 18px
// }

// .font-large {
//     font-size: $font-size-base * 1.5; // 24px
// }

// .font-larger {
//     font-size: $font-size-base * 1.75; // 28px
// }

// .font-extra-large {
//     font-size: $font-size-base * 2; // 32px
// }

// .font-xxl {
//     font-size: $font-size-base * 3; // 32px
// }

.heading-3 {
  font-size: 28px;
}

.heading-2 {
  font-size: 24px;
}

.heading-1 {
  font-size: 20px;
}

.display-1 {
  font-size: 20px;
}

.display-2 {
  font-size: 24px;
}

.display-3 {
  font-size: 28px;
}

.sub-title-1 {
  font-size: 0.75rem;
}

.sub-title-2 {
  font-size: 0.875rem;
}

.sub-title-3 {
  font-size: 1rem;
}

.sub-title-4 {
  font-size: 1.125rem;
}

.font-size-special {
  font-size: 11px;
}

.body-1 {
  font-size: 12px;
}

.body-2 {
  font-size: 14px;
}

.body-3 {
  font-size: 16px;
}

.body-4 {
  font-size: 18px;
}

.cta {
  color: #d4612b !important;
}

.cta-color {
  color: #d4612b;
}

.cta-light-color {
  color: #f6c192;
}

.default-color {
  color: #464646;
}

.text-1-color {
  color: #111111;
}

.text-2-color {
  color: #666;
}

.text-3-color {
  color: #ffffff;
}

.table-heading-background {
  background-color: #fafafa;
}

.table-body-background {
  background-color: #ffffff;
}

.highlight-selection:hover {
  background-color: #fff0e2;
}
