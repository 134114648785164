.landing-page {
  .stats ul {
    display: flex;
    width: 100%;
  }

  .stats ul li {
    width: 100%;
    text-align: center;
  }

  .stats {
    background: #fff;
    padding: 3em;
  }

  .stats ul li h3 {
    font-family: 'Muli Bold';
    text-align: center;
    font-size: 2.5em;
  }
  .recommendations,
  .top-selling {
    //padding: 5em 0 4em;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .recommendations {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .recommendations h3,
  .top-selling h3 {
    font-size: 2em;
    font-family: 'Muli Bold';
  }

  .recommendation-package-container .load-more {
    width: 100%;
    text-align: center;
    margin: 2em 0 0;
  }

  .recommendation-package-container .load-more a {
    padding: 1em 2em;
    color: #ff8122;
    border: 1px solid #ff8122;
  }

  span.line-item {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, sans-serif;
  }

  .home-banner .left p {
    color: #222222ad;
    margin-bottom: 2em;
  }

  @media (max-width: 1300px) {
    .home-banner .left h1 {
      font-size: 3em;
    }
    .home-banner .left p {
      font-size: 1.2em;
    }
  }

  .landing-page-heading {
    font-family: 'Muli Bold', sans-serif;
  }

  .landing-page.home-banner.left {
    margin-left: 3rem;
  }
}

.home-banner {
  h1 {
    font-size: 2.5rem;
    font-weight: 500;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.stats-container,
.coupon {
  h3 {
    font-size: 1.75rem;
    font-weight: 500;
  }
}

.mobile-landing-page {
  .banner {
    min-height: 15rem;
    padding-top: 1rem;

    h4 {
      font-size: 1.13rem;
    }

    p {
      font-size: 0.75rem;
      padding: 0 1rem 0 1rem;
    }
  }
  .banner img {
    filter: brightness(0.5);
  }
  .banner h4,
  p {
    font-family: 'Muli Bold', sans-serif;
    color: white;
  }
  .questionnaire {
    margin: 1rem;
    background-color: #feebdb;
    padding: 1rem;
    border-radius: 0.3rem;
    .border-radius-left,.border-radius-right{
      border-radius: 0;
    }
    label {
      font-family: 'Muli Bold', sans-serif;
      font-size: 0.75rem;
    }

    .button-container {
      position: relative;
      top: 2.8rem;
      display: block;
      min-width: 0;
    }
  }

  .stats {
    margin-bottom: 2rem;
    .container {
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
        grid-row-gap: 1rem;
      }
      ul li p {
        font-size: 12px;
        color: black;
        font-family: 'Inter', sans-serif;
      }
      ul li h3 {
        font-size: 1.13rem;
        font-family: 'Muli Bold', sans-serif;
      }
    }
  }

  .faq {
    background: white;
    padding-bottom: 0;
    margin-top: 0;
    h3 {
      font-size: 1.13rem;
    }
    .faq-question {
      color: black;
      font-family: 'Muli Bold', sans-serif;
      font-size: 0.75rem;
    }
    .card-body p,
    li,
    thead,
    tbody {
      color: #7e808c;
      font-size: 0.75rem !important;
    }
    .accordion {
      border: 1px solid #feebdb;
      border-radius: 0.3rem;
    }
    .card-header::after {
      font-size: 1.25rem;
      margin-top: 0.5rem;
    }
    .view-more-span {
      font-size: 0.88rem !important;
      color: #d4612b !important;
      font-weight: bold;
    }
  }

  .how-it-works {
    overflow: hidden;
    h2 {
      font-size: 1rem;
      text-align: center;
    }

    .carousel-container,
    .slick-slider {
      border-radius: 0.5rem !important;
    }
    .carousel-container::before {
      right: 0;
      background: none;
      width: auto;
      height: auto;
      top: 0;
      position: relative;
    }

    .slider-indicators {
      padding: 1rem 0 1rem 0;
      background-color: #feebdb;
      button {
        border-radius: 1rem !important;
      }
    }

    .carousel-slider-item {
      flex-direction: column;
      border-radius: 1rem;
    }

    .carousel-slider-item {
      height: auto;
      .image-div {
        width: 100%;
        img {
          width: 80%;
          height: 80%;
        }
      }
      .content-div {
        width: 100%;
        text-align: center;
      }
    }
    .how-it-works-heading {
      font-size: 0.88rem;
      margin-bottom: 0.3rem;
    }

    .how-it-works-content {
      color: black;
      font-size: 0.75rem;
      font-family: 'Inter', sans-serif;
    }
  }

  .product-demo {
    flex-direction: column;
    align-items: center;
    background-color: #feebdb;
    padding-bottom: 2rem;

    .content-div {
      margin-left: 0;
      .play-button-div {
        display: none;
      }
      .heading {
        font-size: 0.75rem;
        font-family: 'Muli Bold', sans-serif;
      }
      .image-snippet-dev {
        margin: 0;
      }
    }
  }
}
