.login-container .left h1 {
  text-align: center;
  color: #fff;
}

.login-container .left ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3em;
}

.login-container .left ul li {
  width: 50%;
  text-align: center;
  padding: 1em;
  color: #fff;
}
.login-container.signup{
  position: absolute;
  background: #efefef url(https://hipcouch-application.s3.ap-south-1.amazonaws.com/hipcouch-quote-website/signup-bg.jpg) 50% 50% no-repeat;
  background-size: cover;
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.login-container {
  display: flex;
  height: 100vh;
  background-color: #fff;

  a {
    color: #ff8123;
  }

  a:hover {
    color: #ff8123;
  }

  .error-msg span {
    display: block;

    svg {
      margin-right: 5px;
    }
  }

  .right {
    width: 50%;
    display: flex;
    justify-content: center;

    .signup-cta {
      span {
        padding: 5px;
      }
      margin-top: 2em;
      display: block;
    }
  }

  .left {
    width: 50%;
    position: relative;
    background: url('../../assets/bg-1.jpeg');
    background-size: cover;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 4em !important;
    flex-direction: column;
    justify-content: center;
    border: none;

    .image-container {
      width: 100%;
      height: 300px;
      position: relative;

      .item {
        width: 100%;
        max-width: 250px;
        height: 200px;
        background-size: cover;
        border-radius: 3px;
        background-position-y: -40px;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 9;
        box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
      }

      &:before {
        content: '';
        width: 100px;
        height: 100px;
        position: absolute;
        bottom: 60px;
        left: -40px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        opacity: 1;
        animation: changeOpacity 3s ease;
      }

      &:after {
        content: '';
        width: 150px;
        height: 150px;
        position: absolute;
        top: -40px;
        right: 110px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        opacity: 1;
        animation: changeOpacity 4s ease;
      }

      @keyframes changeOpacity {
        0% {
          opacity: 0;
        }

        90% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      .item:first-child {
        background-image: url(../../assets/bg-1.jpeg);

        // &:before {
        //   content: '';
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   left: 0px;
        //   background: rgba(0, 0, 0, 0.3);
        // }
      }

      .item:last-child {
        background-image: url(../../assets/bg.jpeg);
        right: 0px;
        top: 60px;
      }
    }
  }

  .left .wrapper h3 {
    font-family: 'CircularStd Black', sans-serif;
    color: #fff;
    margin-bottom: 1em;
  }

  .left .wrapper ul {
    line-height: 30px;
  }

  .left .wrapper {
    width: 100%;
    max-width: 460px;
  }

  .left .wrapper li {
    position: relative;
    padding-left: 50px;
    color: #fff;
    margin-bottom: 30px;
    font-size: 1.1em;
  }

  .left .wrapper li svg {
    position: absolute;
    top: -2px;
    left: 0;
    font-size: 2em;
  }

  .login-form {
    width: 100%;
    max-width: 420px;
  }

  .social .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid rgb(196, 196, 205);
    height: 100%;
    align-items: center;
  }

  .social .icon svg {
    margin-right: 10px;
  }

  .MuiDialog-paperFullScreen {
    position: relative;
  }

  .MuiDialog-container {
    height: 100%;
  }

  .login-logo {
    height: 100px;
    margin-bottom: 2em;
  }

  .social {
    display: flex;
    height: 50px;
  }

  .social .item {
    min-width: 100%;
    position: relative;
    padding-right: 10px;
  }

  .social .item button {
    width: 100% !important;
    position: relative;
    opacity: 0 !important;
  }

  .kep-login-facebook.metro {
    min-height: 44px;
    border-radius: 3px !important;
  }

  .b-100-curved {
    margin: 0.5em 0 2em;
    outline: none !important;
    color: #fff !important;
  }

  .App .white-background-color img {
    display: none !important;
  }

  .App .white-background-color .login-form {
    padding-bottom: 4em;
  }

  @media (max-width: 768px) {
    .left {
      display: none;
    }

    .right {
      width: 100%;
    }

    .social {
      flex-direction: column;
      min-height: 50px;
      height: auto;
    }

    .social .item {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.login-container .left:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.left h3 {
  font-size: 2em;
  font-family: 'Muli Bold', sans-serif;
  color: #ff8122;
}
