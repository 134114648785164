.btn-container {
  text-align: center;
}

.modal-dialog {
  height: 80% !important;
}

.modal-content {
  height: 100% !important;
}

.modal-body {
  height: 100% !important;
}

.modalFormContainer {
  height: 100% !important;
}

.welcomeImage {
  background-size: cover;
}

.centerAlignForm {
  margin-top: 58% !important;
}

.kep-login-facebook {
  text-transform: none !important;
  padding: 5px !important;
  display: inline !important;
  font-size: 1rem !important;
}

.login-logo {
  height: 100px;
}
