.card-header-tabs {
  display: flex;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.alignStart {
  justify-content: start;
  padding-left: 1.5em;
}

.alignCenter {
  justify-content: center;
}

.menuBarActive {
  border: none;
  color: #3e3f46;
  margin-bottom: -1px;
  border-bottom: 3px solid #d4612b !important;
}

.assumptions-body .card-body{
  padding: 1px 15px !important;
  border-radius: 4px !important;
}