.confirmation-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  .confirmation-popup-modal {
    min-width: 28%;
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .heading {
      font-size: 1.5em;
    }
    .description {
      margin: 1em 0;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      .action-buttons {
        width: 50%;
        display: flex;
        gap: 1em;
        //justify-content: flex-end;
      }
    }
  }
}

@media only screen and (max-device-width: 480px) {
  .confirmation-popup {
    .confirmation-popup-modal {
      width: 90%;
      .footer {
        .action-buttons {
          width: 50%;
        }
      }
    }
  }
}
//body {
//  overflow: hidden;
//}
