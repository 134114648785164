.options {
  display: flex;
  flex-direction: row;
}

.option-list-item {
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 33px;
}

.radio-label {
  margin-left: 0.5rem;
}
