.icon {
  cursor: pointer;
}

ul.breadcrumbs {
  margin: 1em 0 1em;
  display: flex;
}

ul.breadcrumbs li {
  padding-right: 1.5em;
  color: #7e808c;
  position: relative;
  font-size: 0.9em;
}

ul.breadcrumbs li:after {
  content: '/';
  position: absolute;
  top: 0;
  right: 7px;
}

ul.breadcrumbs li:last-child:after {
  display: none;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff8123;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  text-align: center;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  justify-content: center; /* center items horizontally, in this case */
  align-items: center; /* center items vertically, in this case */
  height: 300px;
}
