.btn-container {
  text-align: center;
}

.modal-dialog {
  height: 80% !important;
}

.modal-content {
  height: 100% !important;
}

.modal-body {
  height: 100% !important;
}

.modalFormContainer {
  height: 100% !important;
}

.welcomeImage {
  background-size: cover;
}

.centerAlignForm {
  margin-top: 58% !important;
}

.kep-login-facebook {
  text-transform: none !important;
  padding: 5px !important;
  display: inline !important;
  font-size: 1rem !important;
}

.login-logo {
  height: 100px;
}

.password-container {
  position: relative;
}

.forgot-password-link {
  top: 10px !important;
  right: 0 !important;
  position: absolute !important;
}

@media screen and (min-width: 350px) {
  .forgot-password-link {
    font-size: 0.75rem;
    position: inherit;
  }
}
