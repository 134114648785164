.card-item {
  height: 100px;
  width: 250px;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px;
  display: flex;
  position: relative;
}

.section-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.blur-image {
  filter: brightness(0.8);
}

.category-card {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.brand-container {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.upper-case-text {
  text-transform: uppercase;
}

.bold-font {
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #ffffff;
  font-weight: 600;
}

.rating-container {
  position: absolute;
  left: 7px;
  bottom: 7px;
}

.rating-legend {
  font-size: 12px;
  bottom: 4px;
  position: relative;
  left: 5px;
}

.vendor-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #737c88;
}

.vendor-title-pad {
  margin-left: 5px;
  margin-top: 5px;
}

.empty-state {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendor-container {
  margin: 3%;
}

.card-container {
  display: flex;
  flex-flow: row;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.msg-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-form {
  width: 500px;
  height: 300px;
}

.header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.a-cta {
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
  color: #fc8118;
  border: 1px solid #fc8118;
  border-radius: 4px;
}

.search-footer {
  display: flex;
  justify-content: space-between;
}
