.footer {
  background-color: #ffffff;
  //position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1rem;
  color: #616366;
  overflow: hidden;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
}

// .icon {
//   margin-left: 10px;
//   width: 16px;
// }

.mobile-footer-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.5rem 1rem 1.5rem 1rem;
  h4 {
    font-size: 1rem;
    font-family: 'Muli Bold', sans-serif;
  }
  .contact-us-ul {
    li {
      margin-top: 0.5rem;
      span {
        margin-left: 0.3rem;
      }
    }
  }
}
