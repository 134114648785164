.mobile-assumptions-div {
  h6 {
    font-family: 'Muli Bold', sans-serif;
  }
  .assumptions-div * {
    font-size: 0.75rem;
  }
  .cost span {
    font-family: 'Muli Bold', sans-serif;
  }
}
