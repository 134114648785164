.progress-loader-div {
  animation-name: progress;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.right-10 {
  right: 10px;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
