$primary: #ff8123;
@import '~animate.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-size: 1rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Muli Regular';
  src: url('assets/fonts/Muli.ttf');
  /* IE9 Compat Modes */
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: 'CircularStd Medium';
//  src: url(assets/fonts/CircularStd-Medium.ttf);
//  /* IE9 Compat Modes */
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'Muli SemiBold';
  src: url('assets/fonts/Muli-SemiBold.ttf');
  /* IE9 Compat Modes */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli Bold';
  src: url('assets/fonts/Muli-Bold.ttf');
  /* IE9 Compat Modes */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd Black';
  src: url('assets/fonts/CircularStd-Black.ttf');
  /* IE9 Compat Modes */
  font-weight: normal;
  font-style: normal;
}

/* Fonts */
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-BlackItalic.eot');
  src: url('assets/fonts/BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-BlackItalic.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-BlackItalic.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-BoldItalic.eot');
  src: url('assets/fonts/BrandonGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-BoldItalic.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-Bold.eot');
  src: url('assets/fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-Bold.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-LightItalic.eot');
  src: url('assets/fonts/BrandonGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-LightItalic.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-LightItalic.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-Light.eot');
  src: url('assets/fonts/BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-Light.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-Light.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-Black.eot');
  src: url('assets/fonts/BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-Black.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-Black.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-Medium.eot');
  src: url('assets/fonts/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-Medium.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-RegularItalic.eot');
  src: url('assets/fonts/BrandonGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-RegularItalic.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-RegularItalic.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-Regular.eot');
  src: url('assets/fonts/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-Regular.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-MediumItalic.eot');
  src: url('assets/fonts/BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-MediumItalic.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-MediumItalic.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-Thin.eot');
  src: url('assets/fonts/BrandonGrotesque-Thin.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-Thin.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-Thin.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('assets/fonts/BrandonGrotesque-ThinItalic.eot');
  src: url('assets/fonts/BrandonGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BrandonGrotesque-ThinItalic.woff2') format('woff2'),
    url('assets/fonts/BrandonGrotesque-ThinItalic.woff') format('woff'),
    url('assets/fonts/BrandonGrotesque-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

* {
  font-family: 'Brandon Grotesque', sans-serif;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #ff8123;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

input[type='email']:focus,
input[type='password']:focus,
input[type='tel']:focus,
input[type='text']:focus {
  box-shadow: none;
  border-color: #ff8122;
}

.form-control,
button {
  border-radius: 0 !important;
}

.flexbox {
  display: flex;

  &.f-55-45 {
    .left {
      width: 55%;
    }

    .right {
      width: 45%;
    }
  }

  &.f-52-48 {
    .left {
      width: 52%;
    }

    .right {
      width: 48%;
    }
  }

  &.f-60 {
    .left {
      width: 60%;
    }

    .right {
      width: 40%;
    }
  }

  &.f-50 {
    .left {
      width: 50%;
    }

    .right {
      width: 50%;
    }
  }
}

.container {
  margin: 0 auto !important;
}

.details-item .placeholder-img {
  min-width: 274px;
  margin-bottom: 1em;
  max-height: 200px;
  width: 100%;
  padding: 0.5em;
  border-radius: 12px;
}
