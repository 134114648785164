.reset-password-container {
  padding: 2.5rem 1.8rem;
  background: #ffff;
  width: 500px;
  height: 420px;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}

.reset-password-container a {
  margin-top: 1rem;
  color: #ff8123;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password-container h1 {
  font-size: 1.5rem;
}

.reset-password-container form {
  margin: 1rem 0;
}

.reset-password-container label {
  font-size: 0.93rem;
}
