.equals {
  box-sizing: border-box;
  height: 8px;
  width: 1px;
  border: 1px solid #979797;
  transform: rotate(90deg);
}

.oval {
  color: #979797;
}

.MuiSelect-select.MuiSelect-select{
  padding-top: 2px;
  padding-bottom: 9px;
}
#furniture-general-details > .MuiBox-root{
  padding: 10px 0px !important;
}